// Body
$body-bg: #f8fafc;

// Typography
$font-primary: "Roboto", Helvetica, sans-serif;

//Colors
$color-primary-grey: #4E565B;
$color-primary-red: #DA251D;
$color-primary-blue: #373C4E;
$color-blue-text: #4E565B;
$container-max-widths: (
  xxl: 1140px,
);
