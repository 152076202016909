
.binshopsblog_container, .search-result-section {
    color: #fff;
    margin-bottom: 150px;
    .header-wrapper {
        margin-top: 60px;
        &::after {
            height: 0 !important;
        }
    }
    .blog-item{
        max-width: 360px;
        background: linear-gradient(45deg, #B6B5B5 0%, #6B6F7C 80%, #373C4E 100%);
        @include sm {
            margin-bottom: 20px;
        }
        img {
            opacity: .3;
            transition: opacity .3s ease-out;
        }
        &:hover {
            img {
                opacity: 1;  
            }
        }
    }
        
    .blog-inner-item {
        h3 {
            font-size: 1.875rem;
            font-weight: 400;
            color:#fff !important;
            min-height: 80px;
            padding-bottom: 1rem !important;
            &::after {
                height: 0 !important;
            }
            @include lg {
                min-height: 0;
            }
        }
        p {
            color:#fff;
        }
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 35px 30px 0 30px;
        @include xsm {
            padding: 25px 10px 0 10px;
        }
        @include xsm-2 {
            padding: 15px 0 0 0;
            p {
                font-size: .9rem;
                padding-bottom: 0.1rem !important;
            }
            h3 {
                padding-bottom: .5rem !important;
            }
            .post-date {
                margin-bottom: .5rem !important;
            }
        }
        .post-date {
            font-weight: 300;
            text-transform: capitalize;
        }

        .button-sm {
            position: absolute;
            font-weight: 700;
            color: #fff;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            &:hover {
                color: $color-blue-text;
            }
        }
    }
}
.single-post-section, .news-list-section, .search-result-section{
    [class*="col-"],  /* Elements whose class attribute begins with "col-" */
    [class^="col-"] { /* Elements whose class attribute contains the substring "col-" */
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }
    p, a {
        color: #000;
    }
    .header-wrapper {
        margin-top: 60px;
        &::after {
            height: 0 !important;
        }
    }
}

.single-post-section {
    margin-top: -5px;
    .left-column, .share-block {
        border-color: #4e565b81 !important;
    }
    .left-column {
        @include lg {
            border-color: transparent !important;
        }
    }
    .right-column {
        padding-left: 40px !important;
        @include lg {
            padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
        }
    }
    h4 {
        position: relative;
        text-align: start;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        font-weight: 900;
        color: $color-blue-text;
        &::after {
            content: '';
            width: 100%;
            max-width: 500px;
            background-color: #4e565b81;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
        }
    }
    p {
        margin-bottom: .5rem !important;
    }
    .post-date {
        text-transform: capitalize;
    }
    .share-block {
        ul {
            display: flex;
            justify-content: space-around;
            margin: 0 !important;
            padding: 0 !important;
            li {
                list-style: none !important;
                display: inline;
                padding-left: 15px;
                font-size: 1.5rem;
            }
        }
    }
    .button-wrapper {
        text-align: center;
        margin-bottom: 100px;
        .button-bg {
            color: #fff;
            &:hover {
                color: $color-blue-text;
            }
        }
        @include lg {
            margin-bottom: 40px;
        }
    }
    
    .post-content {
        margin-bottom: 100px;
        padding-right: 20px;
        @include lg {
            margin-bottom: 40px;
        }
    }
    .blog_subtitle {
        max-width: 250px;
    }
    .image-wrapper {
        padding: 0 25px;
        @include lg {
            padding: 20px 0;
        }
    }
    .all-posts {
        a {
            color: $color-blue-text;
            font-weight: 700;
        }
    }

    .docs-inner-wrapper {
        max-width: 150px;
        display: flex;
        justify-content: flex-start;
        .image-wrapper {
            text-align: center;
            padding: 0;
            p {
                font-weight: 400;
                padding: 1rem 0;
                text-align: center;
            }
            img {
                max-width: 80px;
            }
        }
    }
    // Soc links
    .fb-fa {
        font-family: 'Font Awesome 6 Brands';
        font-weight: 400;
        &::after {
            content: '\f082';
        }
    }
    .tw-fa {
        font-family: 'Font Awesome 6 Brands';
        font-weight: 400;
        &::after {
            content: '\f099';
        }
    }
    .ln-fa {
        font-family: 'Font Awesome 6 Brands';
        font-weight: 400;
        &::after {
            content: '\f08c';
        }
    }
    .wa-fa {
        font-family: 'Font Awesome 6 Brands';
        font-weight: 400;
        &::after {
            content: '\f232';
        }
    }
}
.search {
    max-width: 500px;
    position: relative;
    i {
        right:20px;
        position:absolute;
        top:54px;
        color: $color-blue-text;
    }
    input[type=submit] {
        visibility: hidden;
    }
    h4 {
        margin-bottom: 1rem;
        padding-bottom: 0;
        color: $color-blue-text;
        &::after {
            height: 0;
        }
    }
}
.pagination-section {
    .justify-content-sm-between {
        flex-direction: column !important;
    }
    .page-item.active {
        .page-link {
            background-color: $color-blue-text;
            border-color: $color-blue-text;
            &:focus {
                box-shadow: 0 0 0 0.25rem #da261d54;
            }
        }
    }
    .page-item:not(.active) {
        .page-link {
            &:focus {
                background-color: #da261d2f;
                box-shadow: 0 0 0 0.25rem #da261d54;
            }
            &:hover {
                background-color: #da261d2f;
                color: #DA251D;
            }
        }
    }
}