
#sub-page{
    
    .banner-subpage {
        background: url("../images/banner_podstran-1x.webp") top no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 300px;
        margin-top: 150px;
        @include lg {
            height: auto;
            min-height: 250px;
        }
        @include md {
            margin-top: 120px;
            min-height: 200px;
        }
        .banner-logo{
            background-color: $color-primary-red;
            position: absolute;
            border-radius: 50%;
            top: 90px;
            left: -25px;
            width: 170px;
            height: 170px;
            display: block;
            text-align: center;
            transition: 0.2s ease-out;
            @include lg {
                top: 60px;
            }
            @include md {
                top: 35px;
                width: 155px;
                height: 155px;
            }
            @include sm {
                left: 0;
            }
        }
        .banner-logo-heading {
            position: relative;
            color: #fff;
            font-size: 3rem;
            margin-left: 12px;
            padding-top: 140px;
            font-weight: 900;
            z-index: 10;
            
            @include lg {
                padding-top: 105px;
            }
            @include md {
                line-height: 3.6rem;
                padding-top: 75px;
                font-size: 2.7rem;
            }
            @include sm {
                margin-left: 35px;
            }
        }
        .banner-logo-text {
            position: relative;
            color: #fff;
            font-size: 1.5rem;
            margin-left: 12px;
            font-style: italic;
            padding-bottom: 30px;
            z-index: 10;
            @include lg {
                padding-bottom: 20px;
            }
            @include md {
                font-size: 1.2rem;
            }
            @include sm {
                margin-left: 35px;
            }
        }
    }
    #durability-banner {
        background: url("../images/banner-trajnost.webp") top no-repeat;
        background-size: cover;
        width: 100%;
        .banner-logo{
            background-color: #719D16;
        }
    }
    h2 {
        font-size: 2.25rem;
        color: $color-blue-text;
     }
     h3 {
        color: $color-blue-text;
        font-size: 1.5rem;
        margin-top: 50px;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        position: relative;
        &::after {
            content: '';
            width: 170px;
            background-color:$color-primary-grey;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
            opacity: .5;
        }
    }
    
    .header-wrapper{
        position: relative;
        padding-bottom: 12px;
        align-items: baseline;
        &::after {
            content: '';
            width: 170px;
            background-color: $color-blue-text;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: width .3s ease-out;
        }
    }
    .header-wrapper[aria-expanded="true"]{
        &::after {
            width: 520px;
            
        }
    }
    .contacts-section-2 {
        .header-wrapper{
            &::after {
                height: 0;
            }
        }
        .form-wrapper{
            padding: 0 !important;
            max-width: 100%;
            background: transparent;
            position: relative;
            .background-input-wrapper {
                padding: 115px 60px 160px 60px;
                max-width: 850px;
                background: linear-gradient(308deg, #ADADB8 0%, #404552 100%);
                margin-bottom: 50px;
                @include xl {
                    margin-bottom: 0;
                }
                @include md {
                    padding: 60px 40px;
                }
                @include xsm {
                    padding: 20px;
                }
                .input-wrapper {
                    height: 330px;
                }
            }
            .background-message-wrapper {
                position: absolute;
                top: 55px;
                right: 0;
                width: 650px;
                padding: 60px 80px 80px 130px;
                background: $color-primary-blue;
                margin-bottom: 50px;
                @include xl {
                    width: auto;
                    max-width: 850px;
                    top: 0;
                    position: relative;
                    padding: 60px 60px 80px 60px;
                }
                @include md {
                    padding: 60px 40px 80px 40px;
                }
                @include xsm {
                    padding: 20px 20px 100px 20px;
                }
                .message-wrapper {
                    height: 330px;
                    @include xl {
                        max-width: 350px;
                    }
                }
            }
           .submit-wrapper {
                max-width: 850px;
                margin: auto;
                margin-bottom: 150px;
                @include xl {
                    margin-left: 0;
                }
           }
            form {
                display: flex;
                flex-direction: column;
                max-width: 100%;  
                input[type=text], input[type=email]{
                    margin-bottom:0;
                    padding-left: 40px;
                    padding-right: 40px;
                    max-width: 350px;
                    height: 40px;
                    &::placeholder{
                        text-align: right;
                        @include xl {
                            text-align: left;
                        }
                        @include xsm {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
                input[type=submit] {
                    margin-bottom:70px;
                    margin-left: 115px;
                    color: #fff;
                    @include xl {
                        margin-left: 0;
                    }
                    @include sm {
                        margin-bottom:0;
                    }
                    @include xsm {
                        width: 200px;
                    }
                }
                textarea {
                    padding: 40px 55px;
                    text-align: left;
                    @include sm {
                        padding: 30px;
                    }
                    @include xsm {
                        padding: 10px;
                    }
                    &::placeholder {
                        text-align: left;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}
#about-us {
    .history-section {
        p, a {
            color: #000000;
        }
        margin-bottom: 140px;
        margin-top: -200px;
        .header-wrapper {
            padding-top: 240px;
        }
        .banner-logo-text {
            max-width: 550px;
        }
        .history-content {
            @include xl {
                margin-bottom: 30px;
                top: 0;
              }
            .content-wrapper {
                height: auto;
                transition: max-height .5s ease-out;
                overflow: hidden;
                .content-toggle {
                    height: auto;
                    max-height: 0;
                    visibility: hidden;
                    transition: max-height 1s ease-out, visibility 1s;
                }
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                .content-toggle {
                    max-height: 500px;
                    visibility: visible;
                    transition: max-height 2s ease-out, visibility 2s;
                }
                #show-less {
                    display: block;
                }
                #show-more {
                    display: none;
                }
            }
            #show-less {
                display: none;
            }
        }
    }
    .vision-section {
        .header-wrapper, .button-wrapper {
            display: none;
        }
    }
}
#products {
    .auto-industry-section, .other-industry-section {
        p, a {
            color: #000000;
        }
    }
    .auto-industry-section {
        margin-top: -200px;
        margin-bottom: 60px;
        .header-wrapper {
            padding-top: 240px;
        }
        .auto-industry-content {
            @include xl {
                position: relative;
                top: 0;
              }
            .content-wrapper {
                height: auto;
                overflow: hidden;
                .content-toggle {
                    height: auto;
                    max-height: 0;
                    visibility: hidden;
                    transition: max-height 1s ease-out, visibility 1s;
                }
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                .content-toggle {
                    max-height: 1000px;
                    visibility: visible;
                    transition: max-height 2s ease-out, visibility 2s;
                }
                #show-less {
                    display: block;
                }
                #show-more {
                    display: none;
                }
            }
            #show-less {
                display: none;
            }
        }
    }
    .other-industry-section {
        margin: 30px 0;
    }
    .products-section {
        margin: 100px 0 120px 0;
        .header-wrapper, .button-wrapper {
            display: none;
        }
    }
}
#development {
    p, a {
        color: #000000;
    }
    h3 {
        color: $color-blue-text;
        font-size: 1.5rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        margin-top: 25px;
        position: relative;
        &::after {
            content: '';
            width: 170px;
            background-color:$color-primary-grey;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
        }
    }
    .banner-logo-text {
        max-width: 550px;
    }
    .dev-process-section {
        margin-top: -200px;
        margin-bottom: 60px;
        .header-wrapper {
            padding-top: 240px;
        }
        .image-gif-wrapper {
            max-width: 350px;
        }
        .dev-process-content {
            margin-bottom: 150px;
            @include lg {
                margin-bottom: 50px;
            }
            @include xl {
                position: relative;
                top: 0;
              }
            .content-wrapper {
                height: auto;
                overflow: hidden;
                .content-toggle {
                    height: auto;
                    max-height: 0;
                    visibility: hidden;
                    transition: max-height 1s ease-out, visibility 1s;
                }
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                .content-toggle {
                    max-height: 1000px;
                    visibility: visible;
                    transition: max-height 2s ease-out, visibility 2s;
                }
                #show-less {
                    display: block;
                }
                #show-more {
                    display: none;
                }
            }
            #show-less {
                display: none;
            }
        }
        .dev-tools, .dev-methods {
            padding: 0 0 0 50px;
            @include sm {
                padding: 0;
            }
        }
        .dev-methods{
            @include lg {
                margin-bottom: 50px;
            }
        }
    }
    .dev-research-section {
        height: 300px;
        background: linear-gradient(81deg, #CBCCD1 0%, #9A9DA6 100%);
        margin-bottom: 145px;
        @include lg {
            margin-bottom: 100px;
        }
        @include lg {
            height: auto;
            p {
                padding-bottom: 2rem;
            }
        }
        .image-wrapper {
            max-width: 365px;
            position: relative;
            top: -20px;
            @include lg {
                max-width: 300px;
                margin: auto;
            }
            @include md {
                max-width: 200px;
            }
        }
        .content-wrapper {
            position: relative;
            .content {
                opacity: 1;
                transition: opacity .3s ease-out;
                p {
                    max-width: 560px;
                }
            }
            .content-toggle {
                position: absolute;
                top: 0;
                opacity: 0;
                transition: opacity .3s ease-out;
                p {
                    max-width: 560px;
                }
            }
            &.change-text {
                .content {
                    opacity: 0;
                }
                .content-toggle {
                    opacity: 1;
                }
            }
        }
    }
    .dev-prototypes-section {
        margin-bottom: 130px;
        ul {
            margin-bottom: 75px;
        }
    }
    .development-slider-section {
        margin-bottom: 130px;
        .header-wrapper, .button-wrapper {
            display: none;
        }
    }
}
#career {
    p, a {
        color: #000000;
    }
    .banner-logo-text {
        max-width: 550px;
    }
    .opportunities-section {
        margin-top: -200px;
        margin-bottom: 60px;
        .header-wrapper {
            padding-top: 240px;
        }
        .opportunities-content {
            @include xl {
                position: relative;
                top: 0;
              }
            .content-wrapper {
                height: auto;
                overflow: hidden;
                .content-toggle {
                    height: auto;
                    max-height: 0;
                    visibility: hidden;
                    transition: max-height 1s ease-out, visibility 1s;
                }
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                .content-toggle {
                    max-height: 500px;
                    visibility: visible;
                    transition: max-height 2s ease-out, visibility 2s;
                }
                #show-less {
                    display: block;
                }
                .opportunities-content-p2-tr, #show-more {
                    display: none;
                }
            }
            #show-less {
                display: none;
            }
        }
    }
    .docs-inner-wrapper {
        padding: 0 !important;
    }
    .docs-wrapper {
        padding-left: 100px;
        @include lg {
            padding-left: 0;
        }
    }
    .document {
        max-width: 180px;
        margin-right: 40px;
        
        .image-wrapper {
            max-width: 78px;
            margin-bottom: 25px;
            @include sm {
                margin: auto;
            }
        }
        p {
            max-width: 180px;
            font-weight: 400;
            @include sm {
                margin: auto;
                text-align: center;
                padding-top: 1rem;
            }
        }
    }
    form {
        #company {
            display: none;
        }
        .button-bg {
            &:hover {
                color: $color-blue-text !important;
            }
        }
    }
    .submit-wrapper {
        @include xl {
            text-align: center;
        }
    }
    
}
#suppliers {
    p, a {
        color: #000000;
    }
    .suppliers-row {
        margin-top: 65px;
        margin-bottom: 100px;
        @include md {
            margin-bottom: 40px;
        }
    }
    h4 {
        position: relative;
        font-size: 1.5rem;
        padding-bottom: 26px;
        margin-bottom: 20px;
        font-weight: 700;
        color: $color-blue-text;
        &::after {
            content: '';
            width: 170px;
            background-color: $color-blue-text;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: width .3s ease-out;
        }
    }
    .content-wrapper {
        @include lg {
            margin-bottom: 50px;
        }
    }
    .docs-wrapper {
        padding-left: 100px;
        @include lg {
            padding-left: 0;
        }
    }
    .document {
        max-width: 180px;
        margin-right: 40px;
        
        .image-wrapper {
            max-width: 78px;
            margin-bottom: 25px;
            @include sm {
                margin: 10px auto;
            }
        }
        p {
            max-width: 180px;
            font-weight: 400;
            @include sm {
                text-align: center;
                margin: auto;
            }
        }
    }
    .header-wrapper {
        &::after {
            width: 90%;
        }
    }
    .contacts-section-2 {
        form {
            #job {
                display: none;
            }
            .button-bg {
                &:hover {
                    color: $color-blue-text !important;
                }
            } 
        }
    }
    .submit-wrapper {
        @include xl {
            text-align: center;
        }
    }
    
}
#durability {
    margin-top: -200px;
    margin-bottom: 100px;
    .header-wrapper {
        padding-top: 240px;
        margin-bottom: 20px;
        .red-circle {
            background-color: #719D16;
        }
        &::after {
            width: 90%;
        }
    }
    h2 {
        &:after {

        }
    }
    h4 {
        position: relative;
        font-size: 1.5rem;
        padding-bottom: 26px;
        margin-bottom: 20px;
        font-weight: 700;
        color: $color-blue-text;
        &::after {
            content: '';
            width: 170px;
            background-color: $color-blue-text;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
            transition: width .3s ease-out;
        }
    }
    @include xl {
        margin-bottom: 250px;
    }
    @include lg {
        margin-bottom: 100px;
    }
    p, a {
        color: #000000;
    }
    .durability-content {
        @include xl {
            position: relative;
            top: 0;
            }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            .content-toggle {
                height: auto;
                max-height: 0;
                visibility: hidden;
                transition: max-height 1s ease-out, visibility 1s;
            }
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        a {
            color: $color-blue-text;
            font-weight: 600;
        }
        &.show-text{
            .content-toggle {
                max-height: 600px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility 2s;
            }
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
        }
        #show-less {
            display: none;
        }
    }
    .docs-wrapper {
        padding-left: 100px;
        @include lg {
            padding-left: 0;
        }
    }
    .document {
        max-width: 180px;
        margin-right: 40px;
        
        .image-wrapper {
            max-width: 78px;
            margin: auto;
            margin-bottom: 25px;
        }
        p {
            max-width: 180px;
            font-weight: 400;
            text-align: center;
            margin: auto;
            @include lg {
            }
        }
    }
}
#contact {
    p {
        color: #000000;
    }
    
    margin-top: 50px;
    .contact-row {
        margin-bottom: 120px;
    }
    .content-wrapper {
        margin-bottom: 2rem;
    }
    .contact-wrapper {
        background-color: #EFEFEF;
        padding: 30px 16px 30px 40px;
        max-width: 465px;
        margin-left: 100px;
        color: $color-blue-text;
        @include lg {
            margin-left: 0;
        }
        .contact-company {
            font-weight: 900;
        }
        .contact-slogan {
            position: relative;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            &::after {
                content: '';
                width: 170px;
                background-color: $color-blue-text;
                height: 1px;
                bottom: 0;
                left: 0;
                position: absolute;
                opacity: .5;
            }
        }
        a {
            color: $color-blue-text;
            font-weight: 400;
        }
    }
    .contacts-section {
        .header-wrapper {
            &::after {
                height: 0;
            }
        }
    }
}
#references {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    } 
    .references-section {
        margin-top: 50px;
        .header-wrapper {
            margin-bottom: 20px;
        }
    }
    .references-content {
        @include xl {
            position: relative;
            top: 0;
        }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        &.show-text{
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
            .content-toggle {
                max-height: 500px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility 2s;
            }
        }
        .content-toggle {
            height: auto;
            max-height: 0;
            visibility: hidden;
            transition: max-height 1s ease-out, visibility 1s;
        }
        
        #show-less {
            display: none;
        }
    }
    .sub-pages-slider-section {
        margin-top: 50px;
        @include lg {
            margin-top: 0px;
        }
        img {
            max-width: 160px;
        }
    }
    .swiper-slide {
        padding: 10px;
    }
    .swiper-button-next {
        right: -10px !important;
        @include sm {
            right: 0 !important;
        }
    }
    .swiper-button-prev {
        left: -10px !important;
        @include sm {
            left: 0 !important;
        }
    }
    .references-map-section {
        margin-bottom: 100px;
        @include lg {
            display: none;
        }
        .map-wrapper {
            max-width: 100vw;
            height: auto;
        }
        #Zemljevid {
            margin: auto;
        }
        svg {
            #Path_1213, #Path_1153, #Path_1235, #Path_1227, #Path_1214, #Path_1214, #Path_1159, #Path_1158, #Path_1149, #Path_1166, #Path_1167, #Path_1157, #Path_1148, #Path_1113, #Path_1145, #Path_1032, #Path_1019, #Path_1168, #Path_1005 {
                &:hover {
                    filter: brightness(0) saturate(100%) invert(20%) sepia(91%) saturate(2918%) hue-rotate(351deg) brightness(88%) contrast(95%);
                }
            }
            #Path_1019:hover {
                #Path_1005 {
                    filter: brightness(0) saturate(100%) invert(20%) sepia(91%) saturate(2918%) hue-rotate(351deg) brightness(88%) contrast(95%);
                }
            }
        }
    }
}
#technology{
    margin-top: 100px;
    margin-bottom: 150px;
    p, a {
        color: #000000;
    }
    .technology-content {
        @include xl {
            position: relative;
            top: 0;
            }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            .content-toggle {
                height: auto;
                max-height: 0;
                visibility: hidden;
                transition: max-height 1s ease-out, visibility 1s;
            }
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        a {
            color: $color-blue-text;
            font-weight: 600;
        }
        &.show-text{
            .content-toggle {
                max-height: 500px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility 2s;
            }
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
        }
        #show-less {
            display: none;
        }
    }
    .header-wrapper {
        margin-bottom: 25px;
        &::after {
            width: 90%;
        }
        @include xl {
            margin-bottom: 20px;
        }
    }
    .technology-subpage {
        max-width: 350px;
        margin-bottom: 3rem;
        @include md {
            margin: 0 auto 2rem auto;
        }
        .image-wrapper {
            background: linear-gradient(45deg, #B6B5B5 0%, #6B6F7C 80%, #373C4E 100%);
            img {
                opacity: .3;
                transition: opacity .3s ease-out;
                }
            }
        &:hover {
            img {
                opacity: 1;  
            }
        }
        .technology-title, .button-sm {
            position: absolute;
            color: #fff;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
        }
        .technology-title {
            top: 33%;
            font-size: 1.8rem;
            font-weight: 700;
            width: 90%;
            line-height: 1.8rem;
            @include xsm {
                font-size: 1.6rem;
            }
        }
        .button-sm {
            top: 60%;
            &:hover {
                color: $color-blue-text;
            }
        }
    }
    
}
#quality-systems{
    margin-top: 100px;
    margin-bottom: 150px;
    p, a {
        color: #000000;
    }
    .quality-systems-content {
        @include xl {
            position: relative;
            top: 0;
            }
        .content-wrapper {
            height: auto;
            transition: max-height .5s ease-out;
            overflow: hidden;
            .content-toggle {
                height: auto;
                max-height: 0;
                visibility: hidden;
                transition: max-height 1s ease-out, visibility 1s;
            }
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        a {
            color: $color-blue-text;
            font-weight: 600;
        }
        &.show-text{
            .content-toggle {
                max-height: 300px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility .5s;
            }
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
        }
        #show-less {
            display: none;
        }
    }
    .header-wrapper {
        margin-bottom: 25px;
        &::after {
            width: 90%;
        }
        @include xl {
            margin-bottom: 20px;
        }
    }
    .quality-systems-subpage {
        max-width: 350px;
        margin-bottom: 3rem;
        @include md {
            margin: 0 auto 2rem auto;
        }
        .image-wrapper {
            background: linear-gradient(45deg, #B6B5B5 0%, #6B6F7C 80%, #373C4E 100%);
            img {
                opacity: .3;
                transition: opacity .3s ease-out;
                }
            }
        &:hover {
            img {
                opacity: 1;  
            }
        }
        .quality-systems-title, .button-sm {
            position: absolute;
            color: #fff;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
        }
        .quality-systems-title {
            top: 33%;
            font-size: 1.8rem;
            font-weight: 700;
            width: 100%;
            line-height: 1.8rem;
            @include xsm {
                font-size: 1.6rem;
            }
        }
        .button-sm {
            top: 60%;
            &:hover {
                color: $color-blue-text;
            }
        }
    }
    
}
#tech-processes {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    }
    .banner-logo-heading {
        @include xsm {
            font-size: 2.4rem !important;
        }
    }
    .header-wrapper {
        &::after {
            width: 90%;
        }
    }
    .tech-casting-section {
        margin-top: -200px;
        margin-bottom: 150px;
        @include lg {
            margin-bottom: 50px;
        }
        .tech-casting-content {
            @include xl {
                position: relative;
                top: 0;
                }
            .content-wrapper {
                height: auto;
                overflow: hidden;
                
            }
            #toggle-button {
                margin-bottom: 2rem;
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                #show-less {
                    display: block;
                }
                #show-more {
                    display: none;
                }
                .tech-toggle-text {
                    max-height: 500px;
                    visibility: visible;
                    transition: max-height 2s ease-out, visibility 2s;
                }
            }
            .tech-toggle-text {
                height: auto;
                max-height: 0;
                visibility: hidden;
                transition: max-height 1s ease-out, visibility 1s;
            }
            #show-less {
                display: none;
            }
        }
        .header-wrapper {
            padding-top: 240px;
            margin-bottom: 25px;
            @include xl {
                margin-bottom: 20px;
            }
        }
    }
    #tech-toggle-text {
        cursor: pointer;
    }

    .tech-processing-section {
        .header-wrapper {
            @include lg {
                margin-top: 50px;
            }
        }
        .image-wrapper {
            @include lg {
                margin-bottom: 50px;
            }
        }
        #tech-processing-toggle {
            cursor: pointer;
            .content-toggle {
                opacity: 1;
                transition: opacity .3s ease-out;
            }
            .content-toggle-hide {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity .3s ease-out;
            }
            &.change-text {
                .content-toggle {
                    opacity: 0;
                }
                .content-toggle-hide {
                    opacity: 1;
                }
            }
        }
    }
    .tech-assembly, .tech-transformation {
        @include lg {
            margin-bottom: 50px;
        }
    }
    #tech-assembly-molding, #tech-transformation-processes {
        margin-top: 100px;
        margin-bottom: 50px;
        @include xl {
            margin-top: 50px;
        }
        p {
            max-width: 500px;
        }
        .tech-content {
            .tech-content-title {
                min-height: 40px;
                margin-bottom: .5rem;
            }
            @include xl {
                position: relative;
                top: 0;
              }
            .teh-content-wrapper {
                height: auto;
                overflow: hidden;
            }
            a {
                color: $color-blue-text;
                font-weight: 600;
            }
            &.show-text{
                z-index: 99;
                #show-less {
                    display: block;
                }
                 #show-more {
                    display: none;
                }
                .tech-toggle-text {
                    visibility: visible;
                    transition: visibility 1s;
                }
            }
            .tech-toggle-text {
                visibility: hidden;
                transition: visibility 1s;
            }
            #show-less {
                display: none;
            }
        }
    }
    #tech-transformation-processes {
        padding-bottom: 100px;
        @include lg {
            padding-bottom: 50px;
        }
        h2 {
            min-height: 90px;
            @include lg {
                min-height: 100%;
            }
            @include xsm {
                font-size: 2rem;
            }
        }
    }
    .teh-content-wrapper {
        height: auto;
        max-height: 80px;
        transition: max-height 1s ease-out;
        @include sm {
            max-height: 110px;
        }
        @include xsm {
            max-height: 130px;
        }
    }
    .tech-content-p1-tr {
        .dots {
            position: absolute;
            opacity: 1;
            transition: all 2s;
        }
        .tr-end {
            visibility: hidden;
            transition: visibility 1s;
        }
    }
    .tech-content.show-text {
        .teh-content-wrapper {
            height: auto;
            max-height: 400px;
            transition: max-height 1s ease-out;
            
            @include sm {
                max-height: 800px;
            }
        }
        .tech-content-p1-tr {
            .dots {
                opacity: 0;
                transition: all .3s;
            }
            .tr-end {
                visibility: visible;
                transition: visibility .2s;
            }
        }
    }
    #tech-processes-slider-03 {
        margin-bottom: 200px;
        @include lg {
            margin-bottom: 100px;
        }
    }
}
#development, #tech-processes, #external-processes, #tech-tools, #quality, #cookies {
    ul {
        padding: 0 !important;
    }
    li {
        list-style: none;
        position: relative;
        font-weight: 300;
        padding-left: 37px;
        margin-bottom: 10px;
        &::before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 90%;
            background-color:$color-primary-red;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }
} 
#external-processes {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    }
    .external-processes-section {
        margin-top: -200px;
        @include lg {
            img {
                margin-top: -80px;
            }
        }
    }
    .external-processes-content {
        height: auto;
        margin-bottom: 100px;
        @include lg {
            margin-bottom: 0;
        }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        &.show-text{
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
            .content-toggle {
                max-height: 200px;
                visibility: visible;
                transition: max-height 1.5s ease-out, visibility 1.5s;
            }
        }
        .content-toggle {
            height: auto;
            max-height: 0;
            visibility: hidden;
            transition: max-height 1s ease-out, visibility .9s;
        }
        #show-less {
            display: none;
        }
    }
    .header-wrapper {
        padding-top: 240px;
        margin-bottom: 20px;
    }
    .sub-pages-slider-section {
        margin-bottom: 150px;
    }
}
#tech-tools {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    }
    .tech-tools-section {
        margin-top: -200px;
        margin-bottom: 100px;
        
    }
    .tech-tools-content {
        @include xl {
            position: relative;
            top: 0;
        }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        &.show-text{
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
            .content-toggle {
                max-height: 500px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility 2s;
            }
        }
        .content-toggle {
            height: auto;
            max-height: 0;
            visibility: hidden;
            transition: max-height 1s ease-out, visibility 1s;
        }
        #show-less {
            display: none;
        }
    }
    .header-wrapper {
        padding-top: 240px;
        margin-bottom: 25px;
        @include xl {
            margin-bottom: 20px;
        }
    }
    .sub-pages-slider-section {
        margin-bottom: 150px;
    }
}
#quality {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    }
    .quality-assurance-section {
        margin-top: -200px;
        margin-bottom: 100px;
        .header-wrapper {
            padding-top: 240px;
            margin-bottom: 20px;
        }
    }
    .slider-mockup-wrapper {
        padding-top: 3rem;
        padding-right: 1rem;
        @include lg {
            padding-top: 0;
            padding-right: 0;
        }
        .sub-pages-slider-section {
            margin-bottom: 0;
        }
        .swiper-slide {
            max-width: 100%;
        }
    }
    .quality-assurance-content {
        .content-wrapper {
            height: auto;
            overflow: hidden;
            
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        &.show-text{
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
            .content-toggle {
                max-height: 200px;
                visibility: visible;
                transition: max-height 1s ease-out, visibility 1s;
                
            }
        }
        .content-toggle {
            height: auto;
            max-height: 0;
            visibility: hidden;
            transition: max-height 0.5s ease-out, visibility .5s;
        }
        
        #show-less {
            display: none;
        }
    }
    .laboratory-section {
        margin-top: 100px;
        margin-bottom: 100px;
        .laboratory-image {
            display: flex;
            justify-content: flex-end;
            @include lg {
                justify-content: center;
            }
        }
        .image-wrapper {
            max-width: 400px;
            img {
                display: inline;
            }
        }
    }
    #tech-toggle-text {
        transition: max-height 1.5s ease-out, visibility 3s;
        .content-toggle {
            transition: max-height 1.5s ease-out, visibility 3s;
        }
        .content-hidden {
            opacity: 0;
            transition: opacity .3s ease-out;
            transition-delay: .5s;
            @include lg {
                opacity: 1;
            }
        }
        &.show-text {
            max-height: 950px !important;
            transition: max-height 1.5s ease-out;
            @include xl {
                max-height: 1200px !important;
            }
            .content-hidden {
                opacity: 1;
                transition: opacity .5s ease-out;
            }
            .content-toggle {
                max-height: 600px !important;
                transition: max-height 1.5s ease-out, visibility 3s;
                @include xl {
                    max-height: 750px !important;
                }
            }
        }
        
    }
    .sub-pages-slider-section {
        margin-bottom: 150px;
    }
    .divider {
        @include lg {
            margin-top: -100px;
        }
    }
}
#management-systems {
    p {
        color: #000000;
    }
    a {
        color: $color-blue-text;
        font-weight: 700;
    } 
    h2 {
        @include xsm {
            font-size: 1.7rem !important;
        }
    }
    .banner-logo-heading {
        line-height: 3.6rem;
        max-width: 550px;
    }
    .image-srt-wrapper {
        margin: 100px auto;
        max-width: 350px;
        @include lg {
            margin: 0 auto 50px auto;
        }
    }
    .automotive-section {
        margin-top: -200px;
        margin-bottom: 100px;
        .header-wrapper {
            padding-top: 240px;
            margin-bottom: 25px;
            @include xl {
                margin-bottom: 20px;
            }
        }
    }
    .automotive-content {
        @include xl {
            position: relative;
            top: 0;
        }
        .content-wrapper {
            height: auto;
            overflow: hidden;
            
        }
        #toggle-button {
            margin-bottom: 2rem;
        }
        &.show-text{
            #show-less {
                display: block;
            }
            #show-more {
                display: none;
            }
            .content-toggle {
                max-height: 500px;
                visibility: visible;
                transition: max-height 2s ease-out, visibility .5s;
            }
        }
        .content-toggle {
            height: auto;
            max-height: 0;
            visibility: hidden;
            transition: max-height 1s ease-out, visibility 1s;
        }
        
        #show-less {
            display: none;
        }
    }
    .accordion {
        @include lg {
            margin-bottom: 50px;
        }
        
    }
    .certificates-section {
        p{
            color: $color-blue-text;
        }
        a {
            color: #fff;
            &:hover {
                color: $color-blue-text;
            }
        }
        h3{
            margin-top: 0;
        }
        .certificates-wrapper {
            margin-bottom: 120px;
            @include lg {
                margin-bottom: 0;
            }
        }
        .header-wrapper {
            &::after {
                height: 0;
            }
        }
        .quality-content {
            display: none;
        }
        .quality-outer-wrapper {
            @include sm {
                padding: 25px 20px;
            }
        }
        .quality-content-2 {
            display: block !important;
            padding: 50px 0px 50px 50px;
            @include sm {
                padding: 60px 0px 0px 30px;
            }
            h4 {
                position: relative;
                font-size: 1.5rem;
                padding-bottom: 20px;
                margin-bottom: 20px;
                font-weight: 700;
                color: $color-blue-text;
                &::after {
                    content: '';
                    width: 170px;
                    background-color: $color-blue-text;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    transition: width .3s ease-out;
                }
            }
            
        }
    }
    .other-docs-section {
        margin-bottom: 130px;
        @include lg {
            margin-bottom: 100px;
        }
    }
    .document {
        display: block;
        max-width: 140px;
        .image-wrapper {
            max-width: 78px;
            margin-bottom: 25px;
        }
        p {
            font-weight: 700;
            color: $color-blue-text;
            margin-bottom: 35px;
        }
    }
}
#cookies {
    p {
        color: #000000;
        max-width: 700px;
    }
    a {
        color: $color-blue-text;
        font-weight: 400;
    } 
    h2 {
        margin-top: 2rem;
        @include xsm {
            font-size: 1.7rem !important;
        }
    }
    h3 {
        color: #4E565B;
        font-size: 1.5rem;
        margin-top: 50px;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        position: relative;
        &::after {
            content: "";
            width: 170px;
            background-color: #4E565B;
            height: 1px;
            bottom: 0;
            left: 0;
            position: absolute;
            opacity: 0.5;
        }
    }
    table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 2rem;
      }
      
      td, th {
          color: $color-blue-text;
        border: 1px solid $color-primary-blue;
        text-align: left;
        padding: 15px;
        min-width: 150px;
        @include md {
            min-width: 0;
            padding: 5px;
        }
        @include xsm {
            font-size: .7rem;
            padding: 2px;
        }
      }
      ul {
          margin-bottom: 4rem !important;
          padding-left: 0 !important;
          li {
              margin-bottom: 1rem;
          }
      }
}
.cookie-consent {
    p {
        color: #000;
        margin-bottom: 0 !important;
    }
    a {
        color: #000;
        font-weight: 300;
        text-decoration: underline;
    }
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #0000007c;
    z-index: 9999991;
    .outer-wrapper {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        width: 1140px;
        height: 100px;
        z-index: 9999999;
        background-color: #fff;
        background:  linear-gradient(-53deg, #d9d9df 0%, #9d9fa5 100%);
        @include xl {
            width: 960px;
        }
        @include lg {
            width: 720px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: auto;
        }
        @include md {
            width: 100%;
        }
        @include sm {
            height: 350px;
        }
        @include xsm {
            height: 400px;
        }
        .inner-wrapper {
            margin-top: -10px;
        }
        .image-wrapper {
            max-width: 120px;
            margin: auto;
        }
        .cookie-text {
            @include lg {
                padding: 2rem;
            }
            @include xsm {
                padding: 2rem 1rem;
            }
        }
        .button-sm {
            color: #000;
            border-color: #000;
            transition: all .3s ease-out;
            @include lg {
                margin-bottom: 2rem;
            }
            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }
}
.tech-equipment-section {
    height: auto;
    max-height: 300px;
    background: linear-gradient(81deg, #CBCCD1 0%, #9A9DA6 100%);
    margin-bottom: 145px;
    transition: max-height .8s ease-in;
    @include xl {
        max-height: 100%;
    }
    &.show-text {
        max-height: 400px;
        transition: max-height .5s ease-in;
    }
    @include lg {
        max-height: 800px !important;
        margin-bottom: 100px;
        p {
            padding-bottom: 2rem;
        }
    }
    .image-wrapper {
        max-width: 365px;
        @include lg {
            max-width: 300px;
            margin: auto;
        }
        @include md {
            max-width: 200px;
            margin: auto;
        }
        img {
            position: relative;
            top: -30px;
            transition: all .5s ease-in;
        }
    }
    .content {
        .row{
            --bs-gutter-x: 0 !important;
        }
    }
}
#tech-toggle-text {
    #tech-toggle-button {
        cursor: pointer;
    }
    .content-toggle {
        height: auto;
        max-height: 0;
        visibility: hidden;
        transition: max-height .8s ease-in, visibility .8s;
        overflow: hidden;
    }
    #tech-show-less {
        display: none;
    }
    &.show-text {
        .content-toggle {
            max-height: 100px !important;
            visibility: visible;
            transition: max-height .5s ease-in, visibility .5s;
            @include xsm{
                max-height: 150px;
            }
        }
        #tech-show-less {
            display: block;
        } 
        #tech-show-more {
            display: none;
        }
        .image-wrapper {
            img {
                top: 50%;
                transform: translateY(-50%);
                transition: all .5s ease-in;
                @include lg {
                    top: -30px;
                    transform: translateY(0);
                }
            }
            
        }
    }
}

.accordion {
    margin-bottom: 145px;
}
.accordion-item  {
    border: none;
    h2 {
        @include sm {
            font-size: 2rem !important;
        }
    }
    .header-wrapper {
        @include xsm {
            padding-bottom: 20px !important;
            // margin-bottom: 15px;
        }
        img {
            transition: all .5s ease-in-out;
            @include md {
                width: 85%;
            }
        }
        &::after {
            width: 100% !important;
            opacity: .5;
        }
    }
    .header-wrapper[aria-expanded="true"]{
        img {
            transform: rotate(180deg);
        }
        &::after {
            width: 30% !important;
        }
    }
}
.document {
    img {
        transition: all .3s ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}