footer {
    background-color: $color-primary-blue;
    color: #fff;
    .row {
        padding-bottom: 50px;
    }
    .footer-logo-wrapper {
        max-width: 187px;
        margin-top: 80px;
        @include lg {
            max-width: 170px;
            margin-top: 50px;
        }
        @include sm {
            max-width: 150px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    h4 {
        text-transform: uppercase;
        margin: 80px 0 35px 0;
        font-size: 18px;
        @include lg {
            margin: 50px 0 20px 0;
        }
    }
    .contacts, .navigation, .links {
        padding: 0 10px;
        @include sm {
            text-align: left;
        }
    }
    .navigation {
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
            }
        }
    }
    .links{
        .sl-links {
            margin-top: 20px;
            a{
                margin-right: 10px;
                transition: opacity .3s ease-out;
                .sl-logo {
                    width: 35px;
                }
            }
            a:hover {
                opacity: .8;
            }            
        }
    }
    .copyright-row {
        position: relative;
        padding-bottom: 0;
        &::before {
            content: ' ';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #918F90;
            top: 0;
            left: 0;
        }
        .copyright-wrapper {
            font-weight: 300;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;
            @include sm {
                text-align: left;
            }
            a {
                position: relative;
                padding-left: 10px;
                margin-left: 5px;
                &::before {
                    content: ' ';
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    background-color: #918F90;
                    top: 0;
                    left: 0;
                }
                @include sm {
                    display: block;
                    margin-top: 10px;
                    padding-left: 0;
                    margin-left: 0;
                    &::before {
                        width: 0;
                    }
                }
            }
        }
    }
}