header {
    transition:  opacity .5s ease-in, visibility .5s;
    .lang-switcher {
        position: absolute;
        top: 90px;
        right: -50px;
        z-index: 99999;
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s ease-out, visibility 1s;
        @include lg {
            right: 0;
        }
        @include md {
            flex-direction: column;
        }
        &.visible {
            visibility: visible;
            opacity: 1;
            transition: opacity .8s ease-out, visibility 1s;
            transition-delay: .5s;
        }
        .slo, .eng {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .image-wrapper{
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
        .slo {
            position: relative;
            @include md {
                padding-bottom: 1rem;
            }
            &::after {
                content: '';
                width: 1px;
                background-color: #918F90;
                height: 100%;
                top: 0;
                right: 0;
                position: absolute;
                @include md {
                    width: 0;
                }
            }
        }
    }
    .navbar {
        padding: 0;
        height: 120px;
        .container-fluid {
            align-items: flex-start;
            padding-left: 0;
        }
        .navbar-brand {
            padding: 18px 0 18px 0;
            margin: 0;
            width: 188px;
            #logo-negative {
                opacity: 0;
                margin-left: -193px;
                @include lg {
                    margin-left: -175px;
                }
                @include sm {
                    margin-left: -155px;
                }
                &.logo-center {
                    opacity: 1;
                }
            }
            #logo-positive {
                opacity: 1;
                &.logo-center {
                    opacity: 0;
                }
            }
            .logo-image  {
                position: relative;
                top:0;
                right: 0;
                height: auto;
                z-index: 999;
                transition:opacity .5s ease-out, right .5s ease-out, top .3s ease-out;
                @include lg {
                    max-width: 170px;
                }
                @include sm {
                    max-width: 150px;
                }
                &.logo-center {
                    right: -250%;
                    transition:opacity 1.2s ease-out, right .5s ease-out, top .3s ease-out;
                    @include xl {
                        right: -200%;
                      }
                    @include lg {
                        right: -145%;
                    }
                    @include md {
                        right: -105%;
                    }
                    @include sm {
                        right: -0;
                    }
                }
            }
        }
        .navbar-toggler {
            border: none;
            border-color: transparent;
            color: #fff;
            z-index: 100;
            padding: 18px 0 18px 0;
            @include lg {
                padding-top: 40px;
            }
            .fas {
                font-size: 2.5rem;
              }
            &[aria-expanded="true"] {
                .fas::before {
                    content: "\f00d";
                    color: #fff !important;
                    font-family: "Font Awesome 5 Free";
                }
            }
            &:focus {
                box-shadow: none;
            }
        }
        .navbar-collapse {
            position: fixed;
            background: linear-gradient(180deg, #3B3B48 0%, #06060F24 23%, #78787D2B 52%, #FFFFFF00 100%);
            background-color: $color-primary-blue;
            z-index: 100;
            visibility: hidden;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 20;
            overflow: hidden;
            transition: opacity .5s ease-out, visibility 2s;
            
            &::before {
                content: " ";
                background: url(../images/Banner/Difa_banner_1920_FIN.webp) center no-repeat;
                position: fixed;
                background-position: top;
                background-repeat: no-repeat;
                background-size: cover;
                top: 0;
                left: 0;
                width: 100%;
                height: 100vh;
                z-index: -100;
                opacity: 0.1;
                margin-left: -17px;
                @media (pointer:coarse) {
                    margin-left: 0;
                }
                @include hi-res {
                    background: url("../images/Banner/Difa_banner_3840_FIN.webp") bottom no-repeat;
                    background-size: cover;
                    height: 100vh;
                }
                @include lg {
                    background: url("../images/Banner/Difa_banner_tablice_FIN.webp") bottom no-repeat;
                    background-size: cover;
                    height: 100vh;
                }
                @include sm-2 {
                    background: url("../images/Banner/Difa_banner_mobile_FIN.webp") bottom no-repeat;
                    background-size: cover;
                    height: 100vh;
                }
              }
            .container {
                max-width: 1550px;
            }
            .main-row {
                position: relative;
                margin-left: auto;
                margin-right: auto;
                margin-top: 170px;
                left: -120rem;
                transition: left .5s ease-out;
                @include lg {
                    margin-bottom: 0;
                    width: 70%;
                }
                @include sm {
                    width: 100%;
                }
            }
            
            .second-column, .third-column {
                position: relative;
                &::after {
                    content: ' ';
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    background-color: #918F90;;
                    top: 0;
                    left: 70px;
                    @include lg {
                        width: 0;
                    }
                }
            }
            .second-column, .third-column {
                padding-left: 130px;
                @include lg {
                    padding-left: 0;
                }
                .row {
                    @include hi-res {
                        margin-bottom: 2.5rem !important;
                    }
                }
            }
            .first-row {
                padding-bottom: 3.75rem;
                @include hi-res {
                    padding-bottom: 6rem;
                }
                @include lg {
                    padding-bottom: 0;
                }
            }
            .second-row {
                padding-bottom: 5rem;
                @include hi-res {
                    padding-bottom: 6rem;
                }
                @include lg {
                    padding-bottom: 0;
                }
            }
            .third-row {
                padding-bottom: 2rem;
                @include lg {
                    padding-bottom: 0;
                }
            }
            .logo-wrapper {
                text-align: center;
                padding: 18px 0 18px 0;
                img {
                    max-width: 187px;
                    height: auto;
                }
            }
            ul.navbar-nav {
                position:relative;
                padding: 0 10px;
                
                @include lg {
                    text-align: left;
                    a {
                        font-size: 1.625rem !important;
                        &::after {
                            left: 0 !important;
                            transform: translateX(0) !important;
                        }
                    }
                }
                li {
                    opacity: 0;
                    transition: opacity 0.5s cubic-bezier(0.58, 0.3, 0.005, 1);
                    padding: 0;
                    margin-bottom: 10px;
                    @include lg {
                        max-width: 300px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                  a {
                    color: #FFFFFF;
                    padding: 0;
                    font-weight: 300;
                    &:hover {
                        color: $color-primary-red !important;
                    }
                    }
                }
                .header-link{
                    position: relative;
                    font-size: 1.625rem;
                    font-weight: 900;
                    padding-bottom: 8px;
                    margin-bottom: 12px;
                    &::after {
                        content: ' ';
                        position: absolute;
                        width: 200px;
                        height: 2px;
                        background-color: #918F90;
                        bottom: 0;
                        left: 0;
                        @include xl {
                            width: 150px;
                            font-size: 1.5rem;
                        }
                        @include lg {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                .subheader-link {
                    position: relative;
                    font-size: 1.25rem;
                    font-weight: 900;
                    padding-bottom: 8px;
                    margin-bottom: 8px;
                    &::after {
                        content: ' ';
                        position: absolute;
                        width: 120px;
                        height: 1px;
                        background-color: #918F90;
                        bottom: 0;
                        left: 0;
                        @include lg {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
                ul.sub-menu {
                    padding-left: 0;
                    z-index: 99999;
                    // transition: left .3s ease-out, visibility 1s;
                    @include lg {
                        position: absolute;
                        width: 80%;
                        max-width: 350px;
                        top:45px;
                        left: 0;
                        padding: 20px 30px;
                        opacity: 0;
                        visibility: hidden;
                        overflow: hidden;
                    }
                    li {
                        @include lg {
                            a {
                                font-size: 1.3rem !important;
                            }
                        }
                        
                        list-style: none;
                        &:nth-child(1) {
                            transition-delay: .5s;
                        }
                        &:nth-child(2) {
                            transition-delay: .6s;
                        }
                        &:nth-child(3) {
                            transition-delay: .7s;
                        }
                        &:nth-child(4) {
                            transition-delay: .8s;
                        }
                        &:nth-child(5) {
                            transition-delay: .9s;
                        }
                        &:nth-child(6) {
                            transition-delay: 1s;
                        }
                        &:nth-child(7) {
                            transition-delay: 1.1s;
                        }
                    }
                }
            }
            &.show {
                visibility: visible;
                opacity: 1;
                transition: opacity .5s ease-out, visibility 2s;

                .main-row {
                    left: 0;
                    transition: left .8s ease-out, visibility 1s;
                }

                @media screen and (max-height: 860px) {
                    overflow-y: auto;
                }

                ul {
                    li {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.white-line-wrapper {
    position: absolute;
    top: 750px;
    left: 0;
    width: 100%;
    @include hi-res {
        top: 70vh;
    }
    @include xl {
        top: 800px
    }
    @include lg {
        display: none;
    }

}
.show-sub-menu {
    background-color: $color-primary-blue !important;
    opacity: 1 !important;
    visibility: visible !important;
    transition:  opacity .5s ease-in, visibility .1s;
    
    
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden; 
    }
    50% {
        visibility: visible;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
  }

  @keyframes fadeOut {
    90% {
        visibility: visible;
    }
    100% {
        visibility: hidden;
    }
  }

// .collapsing {
//     -webkit-transition-delay: .3s;
//     transition-delay: .3s;
//     // visibility: hidden;
// }
// .collapse {
//     -webkit-transition-delay: 2s;
//     transition-delay: 2s;
//     visibility: hidden;
// }
// .collapse.show {
//     -webkit-transition-delay: 3s;
//     transition-delay: 3s;
//     visibility: visible;
// }