
.my-theme {
    width: 1140px !important;
    height: 500px !important;
    margin-top: 230px;
    top: 0% !important;
    left: 50% !important;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.4);
    transition-delay: 0.3s;
    z-index: 999 !important;
    @include xxxl {
        margin-top: 220px !important;
    }
    @include lg {
        margin-top: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
    @include sm {
        width: 100% !important;
    }
    .tooltipster-box {
        background: transparent !important;
        border: transparent !important;
        border-radius: 0 !important;
    }
    .tooltipster-content {
        color: #fff;
        overflow: hidden;
        .tooltipster-image {
            max-width: 400px;
        }
        h2 {
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 30px;
            margin-top: 70px;
            @include lg {
                margin-top: 50px;
            }
            @include md {
                margin-top: 0;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }
        h2::after, p::after {
            content: ' ';
            position: absolute;
            width: 160px;
            height: 2px;
            background-color: #fff;
            bottom: 0;
            left: 0;
        }
        p {
            position: relative;
            font-size: 1.25rem;
            font-weight: 900;
            padding-bottom: 30px;
            margin-bottom: 30px; 
            max-width: 500px;
            line-height: 1.5rem;
            @include lg {
                max-width: 100% !important;
            }
        }
        .button-sm {
            background-color: #fff;
            color: $color-primary-grey;
            margin-bottom: 70px;
            padding: 9px;
            font-weight: 700;
            @include lg {
                margin-left: calc((100vw - 160px) / 2);
            }
        }
        .button-sm:hover {
            color: $color-primary-red;
        }
    }
}
.hero-theme {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    background: rgba(0, 0, 0, 0.4);
    transition-delay: 0.3s;
    z-index: 999 !important;
    .tooltipster-image-wrapper {
        max-width: 80%;
        margin: auto;
        @include lg {
            max-width: 90%;
        }
        @include md {
            max-width: 100%;
        }
    }
    .tooltipster-content {
        position: relative;
        top: 50%;
        transform: translateY(-49%);
    }
    .tooltipster-box {
        background: transparent !important;
        border: transparent !important;
        border-radius: 0 !important;
    }
}
.map-theme {
   ul {
       padding-left: 1rem !important;
   }
	li {
        font-size: 0.9rem;
        color: #4E565B !important;
    }
	color: #000 !important;
    
    .tooltipster-box {
        background: #fff !important;
        border: transparent !important;
        border-radius: 20px !important;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.288);
    }
    .tooltipster-content {
        
        overflow: hidden;
        p {
            color: #4E565B !important;
            position: relative;
            font-size: 0.9rem;
            margin: 0;
            &:first-child {
                padding-top: 1rem;
                padding-bottom: .2rem;
                font-weight: 900;
            }
            &:last-child {
                padding-bottom: 1rem;
            }
        }
    }
}
