@mixin xsm {
  @media (max-width: 400px) {
    @content;
  }
}
@mixin xsm-2 {
  @media (max-width: 340px) {
    @content;
  }
}
@mixin sm {
  @media (max-width: 575px) {
    @content;
  }
}
@mixin sm-2 {
  @media (max-width: 520px) {
    @content;
  }
}
@mixin md {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin lg {
  @media (max-width: 991px) {
    @content;
  }
}
@mixin xl {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: 1466px) {
    @content;
  }
}
@mixin xxxl {
  @media (max-width: 1680px) {
    @content;
  }
}
@mixin hi-res {
  @media (min-width: 1980px) {
    @content;
  }
}
@mixin h-500 {
  @media (max-height: 500px) {
    @content;
  }
}
@mixin h-750 {
  @media (max-height: 750px) {
    @content;
  }
}
