img.swiper-lazy{
        &::after {
            content: attr(alt);
            display: block;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff; 
        }
}
.row-no-padding {
    margin-left: 0;
    margin-right: 0;
  }
.col-no-padding {
    &[class*="col-"], &[class^="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
    [class*="col-"],  /* Elements whose class attribute begins with "col-" */
    [class^="col-"] { /* Elements whose class attribute contains the substring "col-" */
    padding-left: 0;
    padding-right: 0;
    }
}
#loading {
    position: fixed;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
    background-color: rgb(0, 0, 0);
    z-index: 99999999;
  }
#front-page {
    background: url(../images/Ozadje_zgoraj_1920px.webp) center top no-repeat, url(../images/Ozadje_spodaj_1920px.webp) center bottom no-repeat;
    position: relative;
    background-size: 100% 50.1%;
    z-index: 0;
    @include hi-res {
        background: url(../images/Ozadje_zgoraj_3840px.webp) center top no-repeat, url(../images/Ozadje_spodaj_3840px.webp) center bottom no-repeat;
        background-size: 100% 50.1%;
    }
    @include lg {
        background: none;
    }
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -99;
        background: linear-gradient(180deg, #3B3B48 0.2%, #ffffff00 15%);
        background-position-y: 99vh;
    }
}
.button-sm {
    display: inline-block;
    font-size: 1rem;
    width: 160px;
    height: 40px;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
    text-align: center;
    cursor:pointer;
    padding: 7px;
    transition: 0.3s ease-in;
}
.button-sm:hover {
    background-color: #fff;
    color: $color-primary-grey;
}
.button-bg {
    display: inline-block;
    width: 240px;
    height: 50px;
    background-color: $color-primary-blue;
    border: 1px solid $color-primary-blue;
    text-align: center;
    font-weight: 700;
    letter-spacing: 0.5px;
    cursor:pointer;
    padding: 11px;
    transition: 0.3s ease-in;
}
.button-bg:hover {
    color: $color-primary-blue;
    background-color: #fff;
}
#tt-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: 50px;
    height: 50px;
    z-index: 100;
    background: url(../images/Gor.svg) center top no-repeat;
    background-size: contain;
    transition: all .3s ease-out;
    @include lg {
        right: 20px;
    }
    @include sm {
        bottom: 5px;
        right: 5px;
    }
    &:hover {
        background: url(../images/Gor_hover.svg) center top no-repeat;
        background-size: contain;
    }
}
.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    .red-circle {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        background-color: $color-primary-red;
        border-radius: 50%;
        flex-shrink: 0;
    }
    h2 {
        color: #FFF;
        margin: 0;
    }
}
.overlay-not-visible {
    margin-right: 17px;
    height: 100%; 
    overflow: hidden;
    @media (pointer:coarse) {
        margin-right: 0;
    }
}
.grecaptcha-badge { 
    visibility: hidden; 
    }
.recaptcha-text-main, .recaptcha-text{
    position: absolute;
    
    max-width: 370px;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 0.9rem;
    a {
        color: #fff !important;
        font-weight: 400 !important;
        text-decoration: underline;
    }
}
.recaptcha-text-main {
    bottom: 125px;
    
    @include xl {
        max-width: 300px;
    }
    @include lg {
        bottom: 730px
    }
    @include sm {
        bottom: 755px
    }
}
.recaptcha-text {
    bottom: 65px;
    z-index: 100;
    @include xl {
        // color: #000 !important;
        bottom: -465px;
        a {
            // color: #000 !important;
        }
    }
    @include xsm {
        bottom: -440px
    }
}
main {
    padding: 0;
    margin: 0;
    margin-top: -120px;
    .hero-section {
        background: url("../images/Banner/Difa_banner_1920_FIN.webp") top no-repeat;
        background-size: cover;
        width: 100%;
        height: 100vh;
        @include hi-res {
            background: url("../images/Banner/Difa_banner_3840_FIN.webp") bottom no-repeat;
            background-size: cover;
            height: 100vh;
        }
        @include lg {
            background: url("../images/Banner/Difa_banner_tablice_FIN.webp") bottom no-repeat;
            background-size: cover;
            height: 100vh;
        }
        @include sm-2 {
            background: url("../images/Banner/Difa_banner_mobile_FIN.webp") bottom no-repeat;
            background-size: cover;
            height: 100vh;
        }
        h1{
            position: relative;
            color: #FFFFFF;
            z-index: 11;
            padding-top: 175px;
        }
        
        .banner-wrapper {
            position: relative;
            max-width: 1920px;
            margin: auto;
            height: 100vh;
            .banner-image {
                
                @include xl {
                    margin-top: -100px;
                    transform: scale(1.5);
                }
                @include lg {
                    margin-top: -50px;
                }
                @include md {
                    margin-top: 0;
                    transform: scale(1.8);
                }
            }
            #wireframe-svg-1 {
                position: absolute;
                width: 80px;
                height: auto;
                top: 50%;
                left: 50%;
                opacity: 1;
                transform: translate(-500%, -150%);
                z-index: 10;
                transition: all .3s ease-out;
                cursor: pointer;
                @include h-500 {
                    transform: translate(-500%, -50%);
                }
                @include lg {
                    transform: translate(-400%, -160%);
                }
                @include md {
                    transform: translate(-340%, -180%);
                }
                @include sm {
                    width: 70px;
                    transform: translate(-50%, -320%);
                }
                @include xsm {
                    transform: translate(-50%, -300%);
                }
                @include h-500 {
                    transform: translate(-50%, -100%);
                }
            }
            .wireframe-svg-1-hover {
                opacity: 0 !important;
                transform: translate(-500%, -120%) !important;
                
                @include lg {
                    transform: translate(-400%, -120%) !important;
                }
                @include md {
                    transform: translate(-340%, -140%) !important;
                }
                @include sm {
                    transform: translate(-50%, -320%) !important;
                }
                @include xsm {
                    transform: translate(-50%, -270%) !important;
                }
                @include h-500 {
                    transform: translate(-50%, -70%) !important;;
                }
            }
        }
        .button-wrapper {
            padding-top: calc(95vh - 130px);
            @include sm-2 {
                padding-top: calc(85vh - 130px);
            }
        }
        .hero-button{
            background-color: $color-primary-red;
            position: relative;
            border-radius: 50%;
            width: 130px;
            height: 130px;
            display: block;
            text-align: center;
            transition: 0.2s ease-out;
            z-index: 10;
            cursor: pointer;
            p{
                padding-top: 25px;
                margin: 0;
                font-size: 24px;
                letter-spacing: 0.6px;
                line-height: 1.3;
                font-weight: 400;
            }
            .hero-button-icon {
                .fa-solid {
                    color: #FFFFFF;
                    font-size: 35px;
                }
            }
            &:hover {
                background-color: #FFFFFF;
                p, .fa-solid {
                    color:#000000
                }
            }
            
        }
    }
    .work-section {
        margin-bottom: 200px;
        @include lg {
            margin-bottom: 150px;
        }
        .row-no-padding {
            
            overflow: hidden; 
        }
        .right-image {
            @include lg {
                order: 2;
            }
        }
        .container {
            padding-left: 0;
            padding-right: 0;
        }
        .header-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            padding-left: 1rem;
            h2 {
                color: #FFF;
                margin: 0;
                
            }
        }
        .content-wrapper{
            background:  linear-gradient(53deg, #ADADB8 0%, #3D4350 100%);
            padding: 50px 45px 75px 45px;
            max-height: 345px;
            @include xl {
                padding: 35px 30px 75px 30px;
            }
            @include lg {
                padding: 20px;
            }
            h3{
                position: relative;
                color: #fff;
                padding-bottom: 20px;
                
            }
            h3::after {
                content: ' ';
                position: absolute;
                width: 200px;
                height: 2px;
                background-color: #fff;
                bottom: 0;
                left: 0;
            }
            p {
                margin: 20px 0;
            }
        }
        .work-image-wrapper {
            overflow: hidden; 
            @include lg {
                max-height: 265px;
            }
            img {
                max-width: 100%;
                height: 345px;
                object-fit: cover;
                transition: transform .5s ease-out;
            }
            img:hover {
                transform: scale(1.05);
                transform-origin: 50% 50%;
            }
        }
    }
    .vision-section {
        .header-wrapper {
            h2 {
                color: $color-primary-blue;
                @include lg {
                    // color: $color-primary-grey;
                }
            }
        }
        .content-wrapper {
            position: relative;
            background-color: $color-primary-blue;
            margin-bottom: 150px;
            @include xsm {
                margin-bottom: 70px;
            }
            @include lg {
                margin-bottom: 100px;
            }
            .col-vision, .col-mission {
                color: #fff;
                padding-top: 40px;
                padding-bottom: 40px;
                transition: all .5s ease-out;
                
                h4 {
                    font-size: 24px;
                    position: relative;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }
            .col-vision:hover, .col-mission:hover, .col-values:hover  {
                transform: scale(1.05);
            }
            .col-vision {
                text-align: right;
                padding-right: 70px;
                padding-left: 30px;
                @include xsm {
                    text-align: left;
                    padding: 20px;
                }
                h4::after {
                    content: ' ';
                    position: absolute;
                    width: 200px;
                    height: 2px;
                    background-color: #fff;
                    bottom: 0;
                    right: 0;
                    @include xsm {
                        left: 0;
                    }
                }
            }
            .col-mission {
                padding-right: 30px;
                padding-left: 70px;
                @include xsm {
                    padding: 20px;
                }
                h4::after {
                    content: ' ';
                    position: absolute;
                    width: 200px;
                    height: 2px;
                    background-color: #fff;
                    bottom: 0;
                    left: 0;
                }
            }
            .col-values-wrapper {
                position: absolute;
                
                top: 50%;
                left: 29%;
                transform: translateY(-50%);
                text-align: center;
                color: #fff;
                @include xl {
                    left: 28%;
                }
                @include lg {
                    position: relative;
                    left: 0%;
                    top: 0;
                    transform: translateY(0);
                }
            }
            .col-values {
                background-color: $color-primary-red;
                width: 480px;
                height: 480px;
                border-radius: 50%;
                transition: all .3s ease-out;
                @include xl {
                    width: 420px;
                    height: 420px;
                }
                @include lg {
                    width: 400px;
                    height: 400px;
                    margin: auto;
                }
                @include sm {
                    width: 350px;
                    height: 350px;
                }
                @include xsm {
                    width: 280px;
                    height: 280px;
                }
                h4 {
                    font-size: 30px;
                    padding-top: 55px;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                    position: relative;
                    @include xsm {
                        font-size: 20px;
                        padding-top: 30px;
                    }
                }
                h4::after {
                    content: ' ';
                    position: absolute;
                    width: 145px;
                    height: 2px;
                    background-color: #fff;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                p {
                    font-size: 24px;
                    margin-bottom: 10px;
                    @include xsm {
                        margin-bottom: 0;
                        font-size: 18px;
                    }
                }
                p:nth-child(2) {
                    margin-left: 5px;
                    margin-bottom: 30px;
                    @include lg {
                        margin-bottom: 20px;
                    }
                    @include sm {
                        margin-bottom: 10px;
                    }
                }
                p:nth-child(3) {
                    margin-left: -135px;
                    margin-bottom: 20px;
                    @include xl {
                        margin-bottom: 0;
                    }
                }
                p:nth-child(4) {
                    margin-left: 200px;
                    margin-bottom: 10px;
                    @include lg {
                        margin-left: 180px;
                    }
                    @include sm {
                        margin-left: 160px;
                        margin-bottom: 0;
                    }
                    @include xsm {
                        margin-left: 120px;
                    }
                }
                p:nth-child(5) {
                    margin-left: -200px;
                    margin-bottom: 15px;
                    @include lg {
                        margin-left: -180px;
                    }
                    @include sm {
                        margin-bottom: 0;
                        margin-left: -160px;
                    }
                    @include xsm {
                        margin-left: -140px;
                    }
                }
                p:nth-child(6) {
                    margin-left: 120px;
                    @include lg {
                        margin-left: 50px;
                    }
                    @include sm {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .button-wrapper{
            display: flex;
            justify-content: center;
            margin-bottom: 150px;
            @include lg {
                margin-bottom: 100px;
            }
            @include md {
                justify-content: space-between;
            }
            @include sm {
                justify-content: center;
            }
            a {
                margin-bottom: 15px;
                &:first-child {
                    margin-right: 45px;
                    @include md {
                        margin-right: 0;
                    }
                }
                &:last-child {
                    margin-left: 45px;
                    @include md {
                        margin-left: 0;
                    }
                }
            }
            
        }
    }
    .talents-section {
        margin-bottom: 120px;
        @include lg {
            margin-bottom: 100px;
        }
        .header-wrapper {
            margin-bottom: 40px;
            h2 {
                color: $color-primary-blue;
            }
        }
        .talents-outer-wrapper {
            position: relative;
            background:  linear-gradient(307deg, #ADADB8 0%, #3E4451 100%);
            width: 730px;
            max-height: 600px;
            top: 0;
            left: 0;
            z-index: 1;
            padding: 60px 200px 60px 90px;
            @include xl {
                padding: 30px 150px 30px 30px;
                width: 600px;
                right: 120px;
            }
            @include lg {
                width: 100%;
                right: 0;
                top: 0;
                padding: 30px;
                margin-bottom: 0;
            }
            @include md {
                padding: 20px;
                max-height: 800px;
            }
            .talents-inner-wrapper {
                background-color: #fff;
                transition: transform .3s ease-out;
                @include xl {
                    //margin-left: 120px;
                }
                @include lg {
                    max-width: 500px;
                    margin: auto;
                }
                .talents-content {
                    padding: 30px 0 30px 30px;
                    @include xsm {
                        padding: 10px;
                    }
                    h3{
                        color: $color-primary-blue;
                        position: relative;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                    h3::after {
                        content: ' ';
                        position: absolute;
                        width: 200px;
                        height: 2px;
                        background-color: $color-primary-blue;
                        bottom: 0;
                        left: 0;
                    }
                    p {
                        color:  $color-primary-blue;
                        max-width: 400px;
                    }
                    p:nth-child(2) {
                        margin-bottom: 25px;
                    }
                    p:nth-child(3) {
                        margin-bottom: 30px;
                    }
                    .button-wrapper {
                        @include lg {
                            text-align: center;
                        }
                    }
                    .button-bg {
                        color: #fff;
                        width: 280px;
                        &:hover {
                            color: $color-primary-blue;
                        }
                        @include xsm {
                            height: 75px;
                            width: 180px;
                        }
                    }
                }
            }
            .talents-inner-wrapper:hover {
                transform: scale(1.03)
            }
            
        }
        .image-outer-wrapper {
            position: relative;
            background:  $color-primary-blue;
            // width: 730px;
            top: 0;
            left: 0;
            padding: 55px 35px 55px 0px;
            @include lg {
                padding: 30px;
            }
            @include md {
                padding: 20px;
            }
            .image-inner-wrapper {
                position: relative;
                z-index: 1;
                margin: auto;
                max-width: 500px;
            }
        }
    }
    .products-section {
        margin-bottom: 200px;
        @include lg {
            margin-bottom: 100px;
        }
        .header-wrapper {
            margin-bottom: 30px;
            h2 {
                color: $color-primary-blue;
            }
        }
        .hover-image{
            display: none;
            position: fixed !important;
            top:0;
            left: 0; 
            width: 100%;
            height: 100%;
            background-color: #0000008f;
            z-index: 1000;
            opacity: 1;
            img {
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60% !important;
                height: auto;
                
                @include lg {
                    width: 70% !important;
                }
                @include md {
                    width: 100% !important;
                }
                @include h-500 {
                    width: auto !important;
                    height: 100%;
                }
            }
        }
    }
    .certificates-section {
        .header-wrapper {
            margin-bottom: 40px;
            h2 {
                color: $color-primary-blue;
            }
        }
        .certificates-wrapper{
            background-color: $color-primary-blue;
            //width: 600px;
            height: 430px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 290px;
            @include lg {
                margin-bottom: 0;
            }
            .certificate {
                display: inline-block;
                background-color: #fff;
                margin: 8px 115px;
                text-align: center;
                transition: transform .3s ease-out;
                z-index: 10;
                @include xl {
                    margin: 20px 80px;
                }
                @include sm {
                    margin: 20px;
                }
                p{
                    color: $color-primary-blue;
                    padding: 10px 0;
                    margin: 0;
                    font-weight: 700;
                }
            }
            .certificate:hover {
                transform: scale(1.08);
            }
        }
        
        .quality-outer-wrapper {
            position: relative;
            background:  linear-gradient(53deg, #ADADB8 0%, #3D4350 100%);
            width: 730px;
            max-height: 800px;
            top: -80px;
            right: 180px;
            padding: 60px 90px 60px 170px;
            @include xl {
                width: 600px;
                right: 120px;
                padding-left: 80px;
                
            }
            @include lg {
                width: 100%;
                right: 0;
                top: 0;
                padding-left: 80px;
                margin-bottom: 100px;
            }
            @include sm {
                padding: 50px 20px;
            }
            .quality-inner-wrapper {
                background-color: #fff;
                transition: transform .3s ease-out;
                @include xl {
                    //margin-left: 120px;
                }
                @include md {
                    //margin-left: 80px;
                }
                .quality-content {
                    padding: 60px 30px;
                   
                    @include xsm {
                        padding: 20px;
                    }
                    h3{
                        color: $color-primary-blue;
                        position: relative;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                    h3::after {
                        content: ' ';
                        position: absolute;
                        width: 200px;
                        height: 2px;
                        background-color: $color-primary-blue;
                        bottom: 0;
                        left: 0;
                    }
                    p {
                        color:  $color-primary-blue;
                        max-width: 400px;
                    }
                    p:nth-child(2) {
                        margin-bottom: 25px;
                    }
                    p:nth-child(3) {
                        margin-bottom: 30px;
                    }
                    .button-bg {
                        color: #fff;
                        @include xsm {
                            width: 160px;
                        }
                    }
                    .button-wrapper {
                        @include lg {
                            text-align: center;
                        }
                    }
                    .button-bg:hover {
                        color: $color-primary-blue;
                    }
                }
            }
            .quality-inner-wrapper:hover {
                transform: scale(1.03)
            }
        }
    }
    .technology-section {
        margin-bottom: 280px;
        @include xl {
            margin-bottom: 150px;;
        }
        @include lg {
            margin-bottom: 100px;;
        }
        .header-wrapper {
            margin-bottom: 30px;
            
            h2 {
                color: $color-primary-blue;
            }
        }
        .technology-wrapper {
            // justify-content: space-evenly;
            // margin: auto;
            // margin-bottom: 280px;
            // max-width: 1920px;
            .technology-content {
                background:  linear-gradient(53deg, #ADADB8 0%, #3D4350 100%);
                // padding:0 10px;
                position: relative;
                // max-width: 500px;
                // margin-bottom: 50px;
                @include sm {
                    padding: 0;
                }
                img {
                    @include lg {
                        opacity: 0.3;
                    }
                }
                .image-01{
                    margin-left: 120px;
                    @include lg {
                        margin-left: 0;
                    }
                }
                .image-02{
                    transform: scale(1.2);
                    position: relative;
                    z-index: 10;
                    @include lg {
                        transform: scale(1);
                    }
                }
                .image-03{
                    margin-left: -120px;
                    @include lg {
                        margin-left: 0;
                    }
                }
                .button-sm{
                    position: absolute;
                    opacity: 0;
                    background-color: #fff;
                    color: $color-primary-blue;
                    font-weight: 700;
                    top: 60%;
                    left: 50%;
                    z-index: 20;
                    transition: opacity .6s ease-in-out, color .3 ease-out;
                    @include lg {
                        opacity: 1;
                    }
                }
                .technology-title {
                    position: absolute;
                    opacity: 0;
                    color: #fff;
                    font-weight: 700;
                    font-size: 1.875rem;
                    top: 30%;
                    left: 50%;
                    width: 100%;
                    text-align: center;
                    transform: translateX(-50%);
                    z-index: 20;
                    transition: opacity .6s ease-in-out;
                    line-height: 2.2rem;
                    z-index: 99999;
                    @include lg {
                        opacity: 1;
                    }
                    @include xsm {
                        top: 25%;
                        width: 95%;
                        font-size: 1.6rem;
                    }
                }
                .button-sm:hover{
                    color: $color-primary-red;
                }
            }
        }
        .technology-content:hover {
            img {
                opacity: 0.3;
                transform: scale(1) !important;
            }
            // .image-01{
            //     margin-left: 0;
            // }
            // .image-02{
            //     transform: scale(1);
            // }
            // .image-03{
            //     margin-left: 0;
            // }
            .button-sm{
                // margin-top:30px;
                opacity: 1;
            }
            .technology-title{
                opacity: 1;
            }
        }
    }
    .contacts-section {
        .contacts-row {
            position: relative;
            margin-bottom: 150px;
            @include lg {
                margin-bottom: 100px;;
            }
        }
        .header-wrapper {
            margin-bottom: 30px;
            h2 {
                color: $color-primary-blue;
            }
        }
        .contacts-wrapper {
            position: relative;
        }
        .form-wrapper{
            padding-left: 60px;
            padding-top: 155px;
            max-width: 850px;
            background: linear-gradient(308deg, #ADADB8 0%, #404552 100%);
            p {
                font-weight: 700;
            }
            @include lg {
                padding-top: 70px;
                width: 100%;
            }
            @include sm {
                padding: 100px 30px;
            }
            form {
                display: flex;
                flex-direction: column;
                max-width: 355px;
                @include xl {
                    max-width: 300px;
                }
                @include sm {
                    width: 100%;
                }
                input[type=text], input[type=email]{
                    padding-left: 40px;
                    padding-right: 40px;
                    margin-bottom:30px ;
                    height: 40px;
                    @include xsm {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    &::placeholder {
                        text-align: right;
                        @include lg {
                            text-align: left;
                        }
                    }
                }
                input[type=submit] {
                    margin-bottom:70px;
                    margin-left: 115px;
                    color: #fff;
                    @include lg {
                        margin-left: 0;
                    }
                    @include sm {
                        margin-bottom:0;
                    }
                    @include xsm {
                        width: 200px;
                    }
                }
                input:hover {
                    color: $color-primary-blue;
                }
                textarea {
                    padding: 10px 40px;
                    margin-bottom:90px;
                    @include lg {
                        margin-bottom:110px;
                    }
                    @include xsm {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    &::placeholder {
                        text-align: right;
                        @include lg {
                            text-align: left;
                        }
                    }
                }
            }
        }
        .map-wrapper {
            @include lg {
                position: relative;
                padding: 20px;
                margin-bottom: 110px;
            }
        }
        .map-background {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 560px;
            height: 685px;
            background-color: $color-primary-blue;
            z-index: 100;
            transition: all .5s ease-out;
            @include xl {
                width: 500px;
            }
            @include lg {
                width: 100%;
                top: 0;
                transform: translateY(0);
                height: 600px;
            }
        }
        .map-frame{
            position:absolute;
            right: 50px;
            bottom: 210px;
            width: 600px;
            height: 450px;
            transition: all .5s ease-out;
            text-align: center;
            
            @include xl {
                width: 500px;
            }
            @include lg {
                position:relative;
                left: 0;
                top: 0;
                width: 100%;
            }
            iframe {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 200;
                margin-bottom: 70px;
                @include lg {
                    margin-bottom: 40px;
                }
            }
        }
        .button-sm{
            position: relative;
            font-weight: 700;
            opacity: 0;
            visibility: hidden;
            z-index: 300;
            transition: opacity .3s ease-out;
            @include lg {
                opacity: 1;
                visibility: visible;
            }
        }
        .button-sm:hover{
            color: $color-primary-red;
        }
        @media only screen and (min-width : 991px) {
            .map-wrapper:hover {
                .map-background {
                    width: 100vw;
                    height: 820px;
                    right: calc((1140px - 100vw) / 2);
                }
                .map-frame{
                    width: 100%;
                    height: 545px;
                    right: 0;
                }
                .button-sm{
                    opacity: 1;
                    visibility: visible;
                    
                }
            }
        }
    }
    .divider {
        width: 100%;
        height: 45px;
        position: relative;
        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            top: 50%;
            left: 0;
            background-color: $color-blue-text;
            opacity: .5;
        }
        .red-circle {
            position: relative;
            margin: auto;
            width: 45px;
            height: 45px;
            border-radius: 90%;
            z-index: 10;
            background-color: $color-primary-red;
        }
    }
}