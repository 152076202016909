.slider-prod-wrapper, .sub-pages-slider-wrapper {
    position: relative;
    max-width: 1920px;
    margin: auto;
    
}
#home-slider {
    img {
        cursor: pointer;
    } 
}
#products-swiper, .swiper {
    // image-rendering: -moz-crisp-edges;         /* Firefox */
    // image-rendering:   -o-crisp-edges;         /* Opera */
    // image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    // image-rendering: crisp-edges;
    // -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    width: 90%;
    margin-bottom: 100px;
    @include md {
        margin-bottom: 50px;
    }
    @include sm {
        width: 100%;
    }
    .swiper-slide-wrapper {
        position: relative;
        margin: auto;
        text-align: center;
        max-width: 369px;
    }
    .swiper-slide {
        position: relative;
        color: #fff;
        font-size: 18px;
        margin: auto;
        text-align: center;
        img {
            width: 100%;
            height: 100%;
            transform: scale(1);
            transition: all .3s ease-out;
            max-width: 369px;
        }
        img:hover {
            transform: scale(1.1);
        }
        .swiper-header {
            position: absolute;
            top: 32%;
            width: 100%;
            text-align: center;
        }
        .button-sm {
            position: absolute;
            top: 63%;
            //left: 29%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.swiper-button-prev, .swiper-button-next{
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
    @include md {
        display: none !important;
    }
}
.swiper-bullets {
    display: none;
    text-align: center;
    margin-bottom: 50px;
    @include md {
        display: block;
        .swiper-pagination-bullet-active {
            background: $color-primary-blue;
        }
    }
}
.swiper-button-next::after{
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none!important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    color: rgb(224, 224, 224);
    line-height: 1;
}
.swiper-button-next{
    right: 20px !important;
}
.swiper-button-prev, .swiper-button-prev-prod{
    left: 20px !important;
}
.swiper-button-next::after{
    content: 'next';
    color: $color-primary-grey
}
.swiper-button-prev::after {
    content: 'prev';
    color: $color-primary-grey
}