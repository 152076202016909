/* Typography
================================================== */

/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto/roboto-v29-latin-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
#app{
  overflow: hidden;
  position: relative;
}

body {
  position: relative;
  font-family: $font-primary !important;
  font-size: 16px !important;
  letter-spacing: 0.4px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
  @include lg {
    background-color: #fff !important;
}
  .wow {
    visibility: hidden;
  }
  @include lg {
    background-size: auto 50%;
  }
}
@media (min-width: 1400px){
  .container{
    max-width:1140px;
  }
}
p, a {
  font-family: $font-primary !important;
  font-weight: 300;
  color: #fff;
}
a, a:visited{
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}
a:hover, a:active, a:focus {
  color: $color-primary-red !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-family: $font-primary;
  letter-spacing: 0.85px;
}
h1 {
  font-size: 1.375rem;
  font-weight: 900;
}
h2 {
  font-size: 2.125rem ;
  font-weight: 900;
  letter-spacing: 0.85px;
  @include sm {
    font-size: 1.875rem;
  }
}
h3 {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: 0.75px;
  @include sm {
    font-size: 1.5rem;
  }
}
h5 {
  color: $color-blue-text;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0;
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}
img {
  width: 100%;
  height: auto;
}